<template>
	<div class="container" ref="getheight">
		<div class="flex-row align-center top-sec space-between">
			<div class="flex-row align-center">
				<img src="../assets/project_icon.png" class="project-icon" />
				<div class="project-icon-text" @click="actTopic">
					{{itemData.list.length>0?itemData.list[itemData.index].name:''}}
				</div>
			</div>
			<div class="flex-row align-center">
				<div class="topic-btn" @click="createTopic()">发表话题</div>
				<div class="tian-btn" @click="createTian()">发表提案</div>
			</div>
		</div>
		<div class="tab-sec flex-row align-center">
			<div class="tab-item flex-column justify-center relative" @click.stop="changeTab(1)">
				<div :class="tab==1?'tab-item-name-active':'tab-item-name'">话题</div>
				<div class="tab-select-border" v-if="tab==1"></div>
			</div>
			<div class="tab-item flex-column justify-center relative" @click.stop="changeTab(2)">
				<div :class="tab==2?'tab-item-name-active':'tab-item-name'">提案</div>
				<div class="tab-select-border" v-if="tab==2"></div>
			</div>
			<div class="tab-item flex-column justify-center relative" @click.stop="changeTab(3)">
				<div :class="tab==3?'tab-item-name-active':'tab-item-name'">投票</div>
				<div class="tab-select-border" v-if="tab==3"></div>
			</div>
		</div>
		<!-- 话题 -->

		<div class="list" v-if="tab==1">
			<div class="item relative " @click="goTian2(index)" v-for="(item,index) in TopicData.recommendProposal"
				:key="index">
				<img src="../assets/tian_icon.png" class="type-icon" v-if="true" />
				<img src="../assets/success_icon.png" class="status-icon" v-if="item.status==8 || item.status == 9" />
				<img src="../assets/fail_icon.png" class="status-icon" v-if="item.status == 7" />
				<div class="flex-row align-center">
					<img v-if="item.userPic" :src="item.userPic" class="head-icon" />
					<img v-else src="../assets/mine/head_icon.png" class="head-icon" />
					<div class="flex-column">
						<div class="flex-row align-center">
							<div class="item-username">{{item.userAddress}}</div>
							<div v-if="item.userId== 163598" class="gf">官方</div>
							<div v-if="item.userId== 163666 || item.userId==163690" class="sgf">项目管理员</div>
						</div>
						<div class="item-time">{{item.createTime}}</div>
					</div>
				</div>
				<div class="flex-row align-center item-title-sec">
					<div class="item-zhiding" v-if="item.isTop == 1">置顶</div>
					<div class="item-title">{{item.title}}</div>
				</div>
				<div class="flex-row align-center progress-title-sec">
					<img src="../assets/progress_icon.png" class="progress-icon" />
					<div class="progress-text">目前进度</div>
				</div>
				<div class="flex-row align-center space-between progress-num-sec">
					<div class="progress-bg">
						<div class="progress-persent-finish" :style="'width:'+item.currentSupportRate+'%;'"></div>
					</div>
					<div class="flex-row align-center">
						<img src="../assets/fire_icon2.png" class="fire-icon"
							v-if="item.status == 2||item.status==8 || item.status == 9" />
						<img src="../assets/fire_icon1.png" class="fire-icon" v-if="item.status == 7" />
						<div class="progress-persent">{{item.currentSupportRate}}%</div>
					</div>
				</div>
				<div class="item-desc">{{item.content}}</div>
				<div class="flex-row align-center  justify-end item-num-sec">
					<div class="flex-row align-center item-comment">
						<img src="../assets/comment_icon.png" class="item-comment-icon" />
						<div class="item-comment-num">{{item.commentTotal}}</div>
					</div>
					<div class="flex-row align-center item-collect">
						<img src="../assets/collect_icon.png" class="item-comment-icon" />
						<div class="item-comment-num">{{item.collectTotal}}</div>
					</div>
					<div class="flex-row align-center item-browse">
						<img src="../assets/browse_icon.png" class="item-browse-icon" />
						<div class="item-comment-num">{{item.viewTotal}}</div>
					</div>
				</div>
			</div>
			<div class="relative item" @click="goInitiadetail2(index)" v-for="(item,index) in TopicData.recommendVote"
				:key="index">
				<img src="../assets/toupiao_icon.png" class="type-icon" />
				<div class="flex-row align-center">
					<img v-if="item.userPic" :src="item.userPic" class="head-icon" />
					<img v-else src="../assets/mine/head_icon.png" class="head-icon" />
					<div class="flex-column">
						<div class="flex-row align-center">
							<div class="item-username">{{item.userNickName}}</div>
							<div v-if="item.userId== 163598" class="gf">官方</div>
							<div v-if="item.userId== 163666 || item.userId==163690" class="sgf">项目管理员</div>
						</div>
						<div class="item-time">{{item.createTime}}</div>
					</div>
				</div>
				<div class="flex-row align-center item-title-sec">
					<div class="item-zhiding" v-if="item.isTop == 1">置顶</div>
					<div class="item-title">{{item.title}}</div>
				</div>
				<div class="item-desc">{{item.content}}</div>
				<div class="item-choice-sec relative">
					<div class="item-choice-sec-modal"></div>
					<div class="flex-row align-center item-choice-item" v-for="(ite,ind) in item.voteOptionList"
						:key="ind">
						<div class="item-choice-icon">{{en[ind]}}</div>
						<div class="item-choice-name">{{ite.voteOption}}</div>
					</div>

				</div>
				<div class="flex-row align-center  justify-end item-num-sec">
					<div class="flex-row align-center item-comment">
						<img src="../assets/comment_icon.png" class="item-comment-icon" />
						<div class="item-comment-num">{{item.commentTotal}}</div>
					</div>
					<div class="flex-row align-center item-collect">
						<img src="../assets/collect_icon.png" class="item-comment-icon" />
						<div class="item-comment-num">{{item.collectTotal}}</div>
					</div>
					<div class="flex-row align-center item-browse">
						<img src="../assets/browse_icon.png" class="item-browse-icon" />
						<div class="item-comment-num">{{item.viewTotal}}</div>
					</div>
				</div>
			</div>
			<van-list v-model="TopicData.loading" :immediate-check="TopicData.immediateCheck" @load="getTopicList"
				:finished="TopicData.finished" finished-text="没有更多了" :error.sync="TopicData.error"
				error-text="请求失败，点击重新加载">
				<div class="relative item" @click="goTopic(index)" v-for="(item,index) in TopicData.list" :key="index">
					<img src="../assets/huati_icon.png" class="type-icon" />
					<div class="flex-row align-center">
						<img v-if="item.userPic" :src="item.userPic" class="head-icon" />
						<img v-else src="../assets/mine/head_icon.png" class="head-icon" />
						<div class="flex-column">
							<div class="flex-row align-center">
								<div class="item-username">{{item.userNickName}}</div>
								<div v-if="item.userId== 163598" class="gf">官方</div>
								<div v-if="item.userId== 163666 || item.userId==163690" class="sgf">项目管理员</div>
							</div>
							<div class="item-time">{{item.createTime}}</div>
						</div>
					</div>
					<div class="flex-row align-center item-title-sec">
						<div class="item-zhiding" v-if="item.isTop == 1">置顶</div>
						<div class="item-title">{{item.title}}</div>
					</div>
					<div class="item-desc">{{item.content}}</div>
					<div class="flex-row align-center  justify-end item-num-sec">
						<div class="flex-row align-center item-comment">
							<img src="../assets/comment_icon.png" class="item-comment-icon" />
							<div class="item-comment-num">{{item.commentTotal}}</div>
						</div>
						<div class="flex-row align-center item-collect">
							<img src="../assets/collect_icon.png" class="item-comment-icon" />
							<div class="item-comment-num">{{item.collectTotal}}</div>
						</div>
						<div class="flex-row align-center item-browse">
							<img src="../assets/browse_icon.png" class="item-browse-icon" />
							<div class="item-comment-num">{{item.viewTotal}}</div>
						</div>
					</div>
				</div>
			</van-list>
		</div>
		<!-- 提案 -->
		<div class="list" v-if="tab==2">
			<van-list v-model="proposalData.loading" :immediate-check="proposalData.immediateCheck"
				@load="getProposalList" :finished="proposalData.finished" finished-text="没有更多了"
				:error.sync="proposalData.error" error-text="请求失败，点击重新加载">
				<div class="item relative " @click="goTian(index)" v-for="(item,index) in proposalData.list"
					:key="index">
					<img src="../assets/tian_icon.png" class="type-icon" v-if="true" />
					<img src="../assets/success_icon.png" class="status-icon"
						v-if="item.status==8 || item.status == 9" />
					<img src="../assets/fail_icon.png" class="status-icon" v-if="item.status == 7" />
					<div class="flex-row align-center">
						<img v-if="item.userPic" :src="item.userPic" class="head-icon" />
						<img v-else src="../assets/mine/head_icon.png" class="head-icon" />
						<div class="flex-column">
							<div class="flex-row align-center">
								<div class="item-username">{{item.userAddress}}</div>
								<div v-if="item.userId== 163598" class="gf">官方</div>
								<div v-if="item.userId== 163666 || item.userId==163690" class="sgf">项目管理员</div>
							</div>
							<div class="item-time">{{item.createTime}}</div>
						</div>
					</div>
					<div class="flex-row align-center item-title-sec">
						<div class="item-zhiding" v-if="item.isTop == 1">置顶</div>
						<div class="item-title">{{item.title}}</div>
					</div>
					<div class="flex-row align-center progress-title-sec">
						<img src="../assets/progress_icon.png" class="progress-icon" />
						<div class="progress-text">目前进度</div>
					</div>
					<div class="flex-row align-center space-between progress-num-sec">
						<div class="progress-bg">
							<div class="progress-persent-finish" :style="'width:'+item.currentSupportRate+'%;'"></div>
						</div>
						<div class="flex-row align-center">
							<img src="../assets/fire_icon2.png" class="fire-icon"
								v-if="item.status == 2||item.status==8 || item.status == 9" />
							<img src="../assets/fire_icon1.png" class="fire-icon" v-if="item.status == 7" />
							<div class="progress-persent">{{item.currentSupportRate}}%</div>
						</div>
					</div>
					<div class="item-desc">{{item.content}}</div>
					<div class="flex-row align-center  justify-end item-num-sec">
						<div class="flex-row align-center item-comment">
							<img src="../assets/comment_icon.png" class="item-comment-icon" />
							<div class="item-comment-num">{{item.commentTotal}}</div>
						</div>
						<div class="flex-row align-center item-collect">
							<img src="../assets/collect_icon.png" class="item-comment-icon" />
							<div class="item-comment-num">{{item.collectTotal}}</div>
						</div>
						<div class="flex-row align-center item-browse">
							<img src="../assets/browse_icon.png" class="item-browse-icon" />
							<div class="item-comment-num">{{item.viewTotal}}</div>
						</div>
					</div>
				</div>
			</van-list>
		</div>
		<!-- 投票 -->
		<div class="list" v-if="tab==3">
			<van-list v-model="voteData.loading" :immediate-check="voteData.immediateCheck" @load="getVoteList"
				:finished="voteData.finished" finished-text="没有更多了" :error.sync="voteData.error"
				error-text="请求失败，点击重新加载">
				<div class="relative item" @click="goInitiadetail(index)" v-for="(item,index) in voteData.list"
					:key="index">
					<img src="../assets/toupiao_icon.png" class="type-icon" />
					<div class="flex-row align-center">
						<img v-if="item.userPic" :src="item.userPic" class="head-icon" />
						<img v-else src="../assets/mine/head_icon.png" class="head-icon" />
						<div class="flex-column">
							<div class="flex-row align-center">
								<div class="item-username">{{item.userNickName}}</div>
								<div v-if="item.userId== 163598" class="gf">官方</div>
								<div v-if="item.userId== 163666 || item.userId==163690" class="sgf">项目管理员</div>
							</div>
							<div class="item-time">{{item.createTime}}</div>
						</div>
					</div>
					<div class="flex-row align-center item-title-sec">
						<div class="item-zhiding" v-if="item.isTop == 1">置顶</div>
						<div class="item-title">{{item.title}}</div>
					</div>
					<div class="item-desc">{{item.content}}</div>
					<div class="item-choice-sec relative">
						<div class="item-choice-sec-modal"></div>
						<div class="flex-row align-center item-choice-item" v-for="(ite,ind) in item.voteOptionList"
							:key="ind">
							<div class="item-choice-icon">{{en[ind]}}</div>
							<div class="item-choice-name">{{ite.voteOption}}</div>
						</div>

					</div>
					<div class="flex-row align-center  justify-end item-num-sec">
						<div class="flex-row align-center item-comment">
							<img src="../assets/comment_icon.png" class="item-comment-icon" />
							<div class="item-comment-num">{{item.commentTotal}}</div>
						</div>
						<div class="flex-row align-center item-collect">
							<img src="../assets/collect_icon.png" class="item-comment-icon" />
							<div class="item-comment-num">{{item.collectTotal}}</div>
						</div>
						<div class="flex-row align-center item-browse">
							<img src="../assets/browse_icon.png" class="item-browse-icon" />
							<div class="item-comment-num">{{item.viewTotal}}</div>
						</div>
					</div>
				</div>
			</van-list>
		</div>
		<!-- 发起提案确认弹窗 -->
		<div class="tian-modal" v-if='tianModal'>
			<div class="tian-modal-con">
				<div class="modal-title">发起提案</div>
				<div class="modal-con">发起提案至少需要持有{{itemData.list[itemData.index].proposalNeedRatio/10}}份相关治理权NFT， 是否确认？
				</div>
				<div class="flex-row align-center modal-btn-sec">
					<div class="cancle-btn" @click="cancleTian()">取消</div>
					<div class="confim-btn" @click="confirmTian()">确认</div>
				</div>
			</div>
		</div>

		<van-popup v-model="showTopic" round position="bottom">
			<van-picker :loading="itemData.loading" title="选择项目" show-toolbar :columns="itemData.columns"
				@confirm="onConfirm" @cancel="onCancel" @change="onChange" />
		</van-popup>
		<!-- 底部tab -->
		<div class="bot-tab-sec flex-row align-center">
			<div class="bot-tab-item flex-column align-center justify-center bot-tab-item-active">
				<img src="../assets/tabbar/home_tab_select.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name-active">首页</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center relative" @click.stop="navCollect()">
				<img src="../assets/tabbar/mine_tab.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">我的</div>
				<div class="red-dot" v-if="remindCount>0"></div>
			</div>
		</div>
		<div class="icp flex-row align-center space-around">
			<div @click.stop="navBa()">沪ICP备17003041号-4</div>
			<div class="flex-row align-center">
				<!-- <img src="../assets/beiab.png" class="beiab-img"> -->
				<div @click.stop="navBa2()">沪公网安备 31011402009626号</div>
			</div>
		</div>
		<LoginWrap v-if="showLogin"></LoginWrap>
	</div>
</template>
<script>
	import config from '../config/config.js';
	import util from '../config/util.js';
	import Vue from 'vue';
	import Vant from 'vant';
	import 'vant/lib/index.css';
	import LoginWrap from '@/components/Login.vue';
	Vue.use(Vant);
	export default {
		data() {
			return {
				en: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'],
				tab: 1, //顶部切换tab 1话题 2提案 3投票
				tianModal: false, //是否展示提案弹窗
				TopicData: {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: false,
					recommendProposal: [],
					recommendVote: []
				},
				proposalData: {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: false,
				},
				voteData: {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: false,
				},
				itemData: {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: true,
					index: 0,
					columns: []
				},
				showTopic: false,
				showLogin: false,
				remindCount: -1
			}
		},
		components: {
			LoginWrap
		},
		methods: {
			navBa2() {
				window.location.href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402009626"
			},
			navBa() {
				window.location.href = "https://beian.miit.gov.cn/#/Integrated/index"
			},
			getRemindCount() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/forum/getRemindCount', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.remindCount = response.data.obj
					} else {
						that.$toast.fail(response.data.msg);
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								that.login()
								//登录
							}, 1200)
						}
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			closeLogin() {
				if (this.$store.state.user.token) {
					this.getRemindCount();
				}
				this.showLogin = false;
			},
			actTopic() {
				this.showTopic = true;
			},
			onConfirm(value, index) {
				this.showTopic = false;
				this.itemData.index = index;
				this.changeTab(1)
			},
			onCancel() {
				this.showTopic = false;
			},
			onChange(picker, value, index) {
				if (index + 1 >= this.itemData.columns.length) {
					this.getNftProjectList();
				}
			},
			getNftProjectList() {
				const that = this;
				if (that.itemData.finished || that.itemData.loading) return;
				that.itemData.loading = true;
				let params = new FormData()
				params.append('pageNum', this.itemData.pageNum)
				params.append('pageSize', this.itemData.pageSize)
				that.axios.post(config.requestUrl + '/front/forum/getNftProjectList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let list = that.itemData.list;
						let newList = response.data.obj.list;

						list = newList;
						if (list.length < that.itemData.pageSize) that.itemData.finished = true;
						that.itemData.pageNum = that.itemData.pageNum + 1;
						that.itemData.loading = false
						that.itemData.list = list;
						let columns = that.itemData.columns;
						let newColumns = []
						for (let i in newList) {
							columns.push(newList[i].name)
						}
						that.itemData.columns = columns
						that.getTopicList()
						that.topicGetVoteList()
						that.getNftProjectList()
					} else {
						that.itemData.error = true
						that.$toast.fail(response.data.msg);
					}
					that.itemData.loading = false
				}, response => {
					that.itemData.error = true
					that.itemData.loading = false
					that.$toast.fail('获取失败');
				})
			},
			changeTab(tab) {
				// if (tab >= 3) {
				// 	this.$toast('敬请期待')
				// 	return;
				// }
				this.TopicData = {
					loading: false,
					error: false,
					finished: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: false,
					recommendProposal: [],
					recommendVote: []
				}

				this.proposalData = {
					loading: false,
					error: false,
					finished: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: false,
				}
				this.voteData = {
					loading: false,
					error: false,
					finished: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: false,
				}

				if (tab == 1) {
					this.topicGetVoteList();
					this.topicGetProposalList();
					this.getTopicList();
				} else if (tab == 2) {
					this.getProposalList();
				} else {
					this.getVoteList();
				}
				this.tab = tab;
			},
			topicGetVoteList() {
				const that = this;
				let params = new FormData()
				params.append('pageNum', 1)
				params.append('pageSize', 20)
				params.append('projectId', this.itemData.list[this.itemData.index].id)
				that.axios.post(config.requestUrl + '/front/forum/topicGetVoteList', params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					if (response.data.code == 0) {
						let newList = response.data.obj.list;
						for (let i in newList) {
							newList[i].content = util.getPlainText(newList[i].content)
							newList[i].userAddress = util.formatAddress(newList[i].userAddress)
							newList[i].currentSupportRate = (newList[i].currentSupportRate).toFixed(2);
						}
						that.TopicData.recommendVote = newList
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败');
				})
			},
			topicGetProposalList() {
				const that = this;
				let params = new FormData()
				params.append('pageNum', 1)
				params.append('pageSize', 20)
				params.append('projectId', this.itemData.list[this.itemData.index].id)
				that.axios.post(config.requestUrl + '/front/forum/topicGetProposalList', params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					if (response.data.code == 0) {
						let newList = response.data.obj.list;
						for (let i in newList) {
							newList[i].content = util.getPlainText(newList[i].content)
							newList[i].userAddress = util.formatAddress(newList[i].userAddress)
							newList[i].currentSupportRate = (newList[i].currentSupportRate).toFixed(2)
						}
						that.TopicData.recommendProposal = newList;
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败');
				})
			},
			async navCollect() {
				this.$router.push('/mine')
			},
			goInitiadetail(index) {
				this.$router.push('/initiatedetail?id=' + this.voteData.list[index].id +
					'&projectId=' + this.voteData.list[index].projectId)
			},
			goInitiadetail2(index) {
				this.$router.push('/initiatedetail?id=' + this.TopicData.recommendVote[index].id +
					'&projectId=' + this.TopicData.recommendVote[index].projectId)
			},
			goTopic(index) {
				this.$router.push('/topicdetail?id=' + this.TopicData.list[index].id +
					'&projectId=' + this.TopicData.list[index].projectId)
			},
			goTian(index) {
				console.log(index)
				this.$router.push('/motiondetail?id=' + this.proposalData.list[index].id +
					'&projectId=' + this.proposalData.list[index].projectId)
			},
			goTian2(index) {
				console.log(index)
				this.$router.push('/motiondetail?id=' + this.TopicData.recommendProposal[index].id +
					'&projectId=' + this.TopicData.recommendProposal[index].projectId)
			},
			createTopic() {
				const that = this;
				if (!this.$store.state.user.token) {
					this.showLogin = 1;
				} else {
					this.$router.push('/createtopic?projectId=' + that.itemData.list[that.itemData.index].id +
						'&name=' +
						that.itemData.list[that.itemData.index].name)
				}
			},
			createTian() {
				if (!this.$store.state.user.token) {
					this.showLogin = 1;
					return;
				}
				this.tianModal = true;
			},
			cancleTian() {
				this.tianModal = false;
			},
			confirmTian() {
				const that = this;
				that.$toast.loading({
					duration: 0
				})
				let params = new FormData()
				params.append('projectId', that.itemData.list[that.itemData.index].id)
				that.axios.post(config.requestUrl + '/front/forum/checkUserCanPushProposal', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.tianModal = false;
					if (response.data.code == 0) {
						that.$toast().clear();
						this.$router.push('/createmotion?projectId=' + that.itemData.list[that.itemData.index].id +
							'&name=' +
							that.itemData.list[that.itemData.index].name)
					} else {
						that.TopicData.error = true
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败');
				})
			},
			navDetail(id, index) {
				this.$router.push('/detail?id=' + id + '&index=' + index)
			},
			navSupport() {
				this.$router.replace('/mine')
			},
			getTopicList() {
				const that = this;
				let pageNum = that.TopicData.pageNum
				that.TopicData.pageNum = that.TopicData.pageNum + 1;
				that.TopicData.loading = true;
				let params = new FormData()
				params.append('pageNum', pageNum)
				params.append('pageSize', this.TopicData.pageSize)
				params.append('projectId', this.itemData.list[this.itemData.index].id)
				that.axios.post(config.requestUrl + '/front/forum/getTopicList', params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let newList = response.data.obj.list;
						for (let i in newList) {
							newList[i].content = util.getPlainText(newList[i].content)
						}
						if (newList.length < that.TopicData.pageSize) that.TopicData.finished = true;
						that.TopicData.list = that.TopicData.list.concat(newList);
					} else {
						that.TopicData.error = true
						that.TopicData.pageNum = that.TopicData.pageNum - 1
						that.$toast.fail(response.data.msg);
					}
					that.TopicData.loading = false;
				}, response => {
					that.TopicData.error = true
					that.TopicData.pageNum = that.TopicData.pageNum - 1
					that.TopicData.loading = false;
					that.$toast.fail('获取失败');
				})
			},
			getProposalList() {
				const that = this;
				if (that.proposalData.loading) return;
				let params = new FormData()
				params.append('pageNum', this.proposalData.pageNum)
				params.append('pageSize', this.proposalData.pageSize)
				params.append('projectId', this.itemData.list[this.itemData.index].id)
				that.axios.post(config.requestUrl + '/front/forum/getProposalList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let newList = response.data.obj.list;
						for (let i in newList) {
							newList[i].content = util.getPlainText(newList[i].content)
							newList[i].userAddress = util.formatAddress(newList[i].userAddress)
							newList[i].currentSupportRate = (newList[i].currentSupportRate).toFixed(2);
						}
						if (newList.length < that.proposalData.pageSize) that.proposalData.finished = true;
						that.proposalData.pageNum = that.proposalData.pageNum + 1;
						that.proposalData.loading = false
						that.proposalData.list = that.proposalData.list.concat(newList);
						console.log(that.proposalData)
					} else {
						that.proposalData.error = true
						that.$toast.fail(response.data.msg);
					}
					that.proposalData.loading = false;
				}, response => {
					that.proposalData.error = true
					that.proposalData.loading = false;
					that.$toast.fail('获取失败');
				})
			},
			getVoteList() {
				const that = this;
				if (that.voteData.loading) return;
				let params = new FormData()
				params.append('pageNum', this.voteData.pageNum)
				params.append('pageSize', this.voteData.pageSize)
				params.append('projectId', this.itemData.list[this.itemData.index].id)
				that.axios.post(config.requestUrl + '/front/forum/getVoteList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let newList = response.data.obj.list;
						for (let i in newList) {
							newList[i].content = util.getPlainText(newList[i].content)
							newList[i].userAddress = util.formatAddress(newList[i].userAddress)
							newList[i].currentSupportRate = (newList[i].currentSupportRate).toFixed(2);
						}
						if (newList.length < that.voteData.pageSize) that.voteData.finished = true;
						that.voteData.pageNum = that.voteData.pageNum + 1;
						that.voteData.loading = false
						that.voteData.list = that.voteData.list.concat(newList);
						console.log(that.voteData)
					} else {
						that.voteData.error = true
						that.$toast.fail(response.data.msg);
					}
					that.voteData.loading = false;
				}, response => {
					that.voteData.error = true
					that.voteData.loading = false;
					that.$toast.fail('获取失败');
				})
			},
		},
		mounted() {
			this.getNftProjectList()
		},
		activated() {
			if (this.$store.state.user.token) {
				this.getRemindCount();

			}
		},
	}
</script>


<style scoped="scoped">
	body {
		background: #000000;
	}

	.container {
		padding-bottom: 170px;
		background: #F5F5F5;
		min-height: 100%;
		width: 100%;
		min-height: 100%;
		overflow: visible;
		box-sizing: content-box;
	}

	.top-sec {
		width: 750px;
		height: 100px;
		background: #000000;
		box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.35);
		border-radius: 0px 0px 10px 10px;
	}

	.project-icon {
		width: 26px;
		height: 21px;
		margin-left: 30px;
	}

	.project-icon-text {
		font-size: 26px;
		color: #FFFFFF;
		line-height: 26px;
		margin-left: 20px;
	}

	.topic-btn {
		width: 150px;
		height: 50px;
		background: #000000;
		border-radius: 26px;
		border: 1px solid #FFFFFF;
		font-size: 26px;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		/* margin-left: 262px; */
	}

	.tian-btn {
		width: 150px;
		height: 50px;
		background: #000000;
		border-radius: 26px;
		border: 1px solid #FFFFFF;
		font-size: 26px;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		margin-left: 20px;
		margin-right: 40px;
	}

	.item {
		width: 710px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 20px 20px 0 20px;
		box-sizing: border-box;
		margin: 20px auto;
	}

	.item2 {
		width: 710px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 20px 20px 0 20px;
		box-sizing: border-box;
		margin: 20px auto;
	}

	.type-icon {
		width: 71px;
		height: 40px;
		position: absolute;
		top: 12px;
		right: -5px;
	}

	.status-icon {
		width: 124px;
		height: 108px;
		position: absolute;
		top: 35px;
		right: 86px;
	}

	.head-icon {
		width: 70px;
		height: 70px;
		margin-right: 20px;
		border-radius: 50%;
	}

	.item-username {
		font-size: 26px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.5);
		line-height: 26px;
	}

	.item-time {
		font-size: 20px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 20px;
		letter-spacing: 1px;
		margin-top: 10px;
	}

	.item-title-sec {
		margin-top: 33px;
	}

	.item-title {
		font-size: 28px;
		color: #000000;
		line-height: 28px;
		letter-spacing: 1px;
	}

	.item-zhiding {
		width: 50px;
		height: 24px;
		background: #FFE3D0;
		border-radius: 2px;
		text-align: center;
		font-size: 18px;
		color: #FA3813;
		line-height: 24px;
		margin-right: 10px;
	}

	.item-desc {
		font-size: 26px;
		color: rgba(0, 0, 0, 0.6);
		line-height: 40px;
		letter-spacing: 1px;
		margin-top: 15px;
		width: 650px;
		max-height: 80px;
		word-break: break-all;
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		/* 这里是超出几行省略 */
	}

	.item-choice-sec {
		width: 670px;
		height: 180px;
		padding: 10px 25px;
		box-sizing: border-box;
		background: #F5F5F5;
		overflow: hidden;
	}

	.item-choice-sec-modal {
		position: absolute;
		top: 0;
		left: 0;
		width: 670px;
		height: 180px;
		background-image: linear-gradient(180deg, rgba(251, 251, 251, 0) 0%, rgba(91, 91, 91, 0.92) 100%);
	}

	.item-choice-item {
		width: 620px;
		padding: 20px;
		/* height: 40px; */
		background: #FFFFFF;
		border-radius: 30px;
		margin-top: 10px;
		padding: 10px 30px;
		box-sizing: border-box;
	}

	.item-choice-icon {
		width: 20px;
		height: 20px;
		font-size: 22px;
		font-weight: bold;
		color: #F7B500;
		line-height: 22px;
	}

	.item-choice-name {
		font-size: 22px;
		color: #000000;
		line-height: 30px;
		margin-left: 25px;

	}

	.item-num-sec {
		margin-top: 20px;
		height: 63px;
		border-top: 1px solid #F5F5F5;
		padding-right: 20px;
	}

	.item-comment {}

	.item-comment-icon {
		width: 21px;
		height: 21px;
	}

	.item-browse-icon {
		width: 22px;
		height: 12px;
	}

	.item-comment-num {
		font-size: 16px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 16px;
		margin-left: 10px;
	}

	.item-collect {
		margin-left: 52px;
	}

	.item-browse {
		margin-left: 52px;
	}

	.progress-title-sec {
		margin-top: 32px;
	}

	.progress-icon {
		width: 32px;
		height: 23px;
	}

	.progress-text {
		margin-left: 10px;
		font-size: 26px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 26px;
		letter-spacing: 1px;
	}

	.progress-num-sec {
		margin-top: 20px;
	}

	.progress-bg {
		width: 540px;
		height: 20px;
		background: rgba(0, 0, 0, 0.03);
		border-radius: 10px;
	}

	.progress-persent-finish {
		width: 407px;
		height: 20px;
		background: linear-gradient(90deg, #FFFFFF 0%, #887AFF 100%);
		border-radius: 10px;
	}

	.fire-icon {
		width: 13px;
		height: 16px;
	}

	.progress-persent {
		font-size: 26px;
		color: #000000;
		line-height: 26px;
		letter-spacing: 1px;
		margin-left: 5px;
	}

	.desc-img-sec {
		margin-top: 19px;
	}

	.item-desc-img {
		width: 202px;
		height: 182px;
		border-radius: 10px;
	}

	/* 发起提案弹窗开始 */
	.tian-modal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
	}

	.tian-modal-con {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 630px;
		height: 394px;
		background: #FFFFFF;
		border-radius: 20px;
	}

	.modal-title {
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 47px;
		margin-top: 39px;
		text-align: center;
	}

	.modal-con {
		width: 550px;
		height: 130px;
		background: rgba(98, 54, 255, 0.1);
		border-radius: 10px;
		text-align: center;
		margin: 30px auto 60px auto;
		padding: 26px 16px;
		box-sizing: border-box;
		font-size: 26px;
		color: #3B2F67;
		line-height: 39px;
	}

	.modal-btn-sec {}

	.cancle-btn {
		width: 315px;
		height: 88px;
		background: #FFFFFF;
		box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 0px 0px 20px;
		text-align: center;
		font-size: 30px;
		color: #000000;
		line-height: 88px;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.confim-btn {
		width: 315px;
		height: 88px;
		background: #FFFFFF;
		box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 0px 20px 0px;
		text-align: center;
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 88px;
	}

	/* 发起提案弹窗结束 */
	/* 顶部切换tab部分开始 */
	.tab-sec {
		width: 750px;
		height: 98px;
		background: #FFFFFF;
	}

	.tab-item {
		width: 33.33%;
		height: 98px;
		text-align: center;
	}

	.tab-item-name {
		font-size: 28px;
		color: #000000;
		line-height: 28px;
	}

	.tab-item-name-active {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 28px;
	}

	.tab-select-border {
		position: absolute;
		bottom: 0px;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 80px;
		height: 5px;
		background: linear-gradient(90deg, #FFFFFF 0%, #6C2FFF 52%, #FFFFFF 100%);
		border-radius: 2px;
	}

	/* 顶部切换tab部分结束 */

	.red-dot {
		width: 14px;
		height: 14px;
		background: #C90909;
		border-radius: 50%;
		position: absolute;
		top: 4px;
		left: 200px;
	}

	.gf {
		margin-left: 10px;
		padding: 0 5px;
		font-size: 10px;
		color: #FFFFFF;
		background: blue;
		border-radius: 4px;
	}
</style>